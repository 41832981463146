import React, {useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'

type Props = {
  className: string
  chartColor: string
  strokeColor: string
  chartHeight: string
}

const TotalOrderDetails: React.FC<Props> = ({className, chartColor, chartHeight, strokeColor}) => {
  useEffect(() => {
    setTimeout(() => {}, 1000)
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Chart */}

        {/* end::Chart */}
        {/* begin::Stats */}
        <div className='card-p mt-n20 position-relative'>
          <h3 className='card-title fw-bolder mt-18 text-success fs-1'>
            Total Orders : <span className='fs-1 text-bold text-info'>6</span>
          </h3>
          {/* begin::Row */}
          <div className='row g-5 mt-2'>
            {/* begin::Col */}
            <div className='col-6 col-sm-6 col-md-4'>
              <div className='bg-light-warning px-6 py-8 rounded-2 mb-3'>
                <p className='display-6 text-bold text-warning'>3</p>
                <Link to={'/countries'} className='text-warning fw-bold fs-6'>
                  New Orders
                </Link>
              </div>
            </div>
            {/* end::Col */}
            {/* begin::Col */}
            <div className='col-6 col-sm-6 col-md-4'>
              <div className=' bg-light-primary px-6 py-8 rounded-2 mb-3'>
                <p className='display-6 text-bold text-primary'>1</p>
                <Link to={'/states'} className='text-primary fw-bold fs-6'>
                  Pickup Orders
                </Link>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-4'>
              <div className=' bg-light-dark px-6 py-8 rounded-2 mb-3'>
                <p className='display-6 text-bold text-dark'>2</p>
                <Link to={'/districts'} className='text-dark fw-bold fs-6'>
                  Pickup Orders
                </Link>
              </div>
            </div>

            {/* end::Col */}
          </div>
          {/* end::Row */}
          {/* begin::Row */}
          {/* <div className='row g-5'>
            <div className='col-6 col-sm-6 col-md-4'>
              <div className='bg-light-danger px-6 py-8 rounded-2'>
                <p className='display-6 text-bold text-danger'>11</p>
                <Link to={'/employee/employee'} className='text-success fw-bold fs-6 mt-2'>
                  No Of Society
                </Link>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-4'>
              <div className=' bg-light-success px-6 py-8 rounded-2'>
                <p className='display-6 text-bold text-success'>15</p>
                <Link to={'/customer'} className='text-success fw-bold fs-6 mt-2'>
                  No Of Area
                </Link>
              </div>
            </div>
            <div className='col-6 col-sm-6 col-md-4'>
              <div className=' bg-light-info px-6 py-8 rounded-2'>
                <p className='display-6 text-bold text-info'>2</p>
                <Link to={'/scripts'} className='text-info fw-bold fs-6 mt-2'>
                  No Of Supervisor
                </Link>
              </div>
            </div>
          </div> */}
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TotalOrderDetails}
